<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/security-privacy" parent-url="安全隐私" current-url="桌面安全" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/img_2.png" />
          <div class="about">
            <div class="title">桌面安全</div>
            <div class="content">
              安全桌面，也叫做Winlogon Desktop。它是Windows系统具有的三个桌面之一（另外两个是应用程序桌面Application Desktop和屏幕保护桌面Screensaver Desktop），是Winlogon和GINA在交互验证（例如权限提升时用户验证过程）和其他安全诊断对话框运行时的桌面。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">DLP</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <div class="company-info">
              通过身份认证和加密控制以及使用日志的统计对内部文件进行控制。在数据泄漏防护市场里面，国内具备自主知识产权的产品生产厂家为数不多。数据泄漏防护技术（DLP）日渐成为市场上最为重要的安全技术之一。企业青睐数据泄漏防护技术来保护所有权数据和满足法规遵从的需要，为想要接触安全市场中最敏感部分的解决方案提供商提供了巨大的商机。数据泄漏防护技术也为安全产品销售商带来了大量机遇。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">杀毒</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <div class="company-info">
              杀毒软件是一种可以对病毒、木马等一切已知的对计算机有危害的程序代码进行清除的程序工具。“杀毒软件”由国内的老一辈反病毒软件厂商起的名字，后来由于和世界反病毒业接轨统称为“反病毒软件”、“安全防护软件”或“安全软件”。集成防火墙的“互联网安全套装”、“全功能安全套装”等用于消除电脑病毒、特洛伊木马和恶意软件的一类软件，都属于杀毒软件范畴。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
